import * as auth from "./firebase/auth";

export const ERROR_MESSAGE = 'Error actualizando el servidor.';

export function doGet(url, onSuccess, onError) {
    return auth.getToken()
        .then(token =>
            fetch(url, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => onSuccess(json));
                } else {
                    throw (new Error(response.status + ' - ' + response.statusText + '. ' + ERROR_MESSAGE));
                }
            })
        ).catch(error => onError(error));
}

export function doDelete(url, onSuccess, onError) {
    return auth.getToken()
        .then(token =>
            fetch(url, {
                mode: 'cors',
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                if (response.ok && response.status !== 204) {
                    response.json().then(json => onSuccess(json));
                } else if (response.status === 204) {
                    onSuccess();
                } else {
                    throw (new Error(response.status + ' - ' + response.statusText + '. ' + ERROR_MESSAGE));
                }
            })
        ).catch(error => onError(error));
}

export const doPost = (url, body, onSuccess, onError) => {
    return auth.getToken()
        .then(token =>
            fetch(url, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body,
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => onSuccess(json));
                } else {
                    throw (new Error(response.status + ' - ' + response.statusText + '. ' + ERROR_MESSAGE));
                }
            })
        ).catch(error => onError(error));
};

export const doPut = (url, body, onSuccess, onError) => {
    return auth.getToken()
        .then(token =>
            fetch(url, {
                mode: 'cors',
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body,
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => onSuccess(json));
                } else {
                    throw (new Error(response.status + ' - ' + response.statusText + '. ' + ERROR_MESSAGE));
                }
            })
        ).catch(error => onError(error));
};

/**
 * Dado un valor para url = 'https://bert.tiivii.com/api/studio/:id/event/:event_id' y un valor para variables = {
 *   id: "un_id", event_id: "un_event_id"}, devolvería https://bert.tiivii.com/api/studio/un_id/event/un_event_id
 * @param url Url con placeholders
 * @param variables objeto con las substituciones a realizar
 * @returns {string} con la url generada a partir de las substituciones.
 */
export const buildUrl = (url, variables) =>
    url.replace(/:(\w+)/g, (match, p1) => p1 in variables ? variables[p1] : match);
