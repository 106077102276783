import React from 'react'
import PropTypes from 'prop-types'

import {Tooltip} from '@material-ui/core'
import PowerIcon from '@material-ui/icons/PowerSettingsNew'

import {POWEROFF, POWERON} from '../../utils/constants'

export default function PowerStatusIcon(props) {
    const {powerStatus} = props;
    const tooltip = powerStatus ? powerStatus === POWERON ? POWERON : POWEROFF : 'No power status data.';
    const color = powerStatus ? powerStatus === POWERON ? 'green': 'black' : 'grey';
    return (<Tooltip title={tooltip}><PowerIcon style={{color: color}}/></Tooltip>)
}

PowerStatusIcon.propTypes = {
    powerStatus: PropTypes.oneOf([POWERON, POWEROFF, undefined]),
};