import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles';
import {compose} from 'recompose'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';

import * as routes from '../utils/routes'
import CardLink from './navigation/CardLink'
import studio from '../images/studio.jpg'

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    nav_menu: {
        marginTop: '24px',
        boxSizing: "border-box",
    }
});

class LandingComponent extends React.Component {
    render() {
        const {authUser, classes} = this.props;
        const LoginLink = React.forwardRef((props, ref) => <Link ref={ref} to={routes.SIGN_IN} {...props} />);
        return (
            <div>
                {authUser
                    ? <Grid className={classes.nav_menu} container direction={"row"} justify={"center"}>
                        <Grid item>
                            <CardLink
                                image={studio}
                                title={"Studio Rooms"}
                                description={"Studio rooms management tools."}
                                to={routes.ROOM_LIST}/>
                        </Grid>
                    </Grid>
                    :
                    <div>
                        <p>
                            BeRT simple UI. Studio room management tool. Please, log in to continue.
                        </p>
                        <Button variant="contained" className={classes.button} component={LoginLink}>
                            Login
                        </Button>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(LandingComponent);
