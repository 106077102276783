import React, {Component} from 'react'
import AbstractTable from '../table/AbstractTable'
import {compose} from 'recompose'
import withAuthorization from '../auth/withAuthorization'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {TextField, Checkbox} from '@material-ui/core'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/es/styles/withStyles'
import EditIcon from '@material-ui/icons/Edit'

import {EDIT} from "../../utils/constants";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '99%',
    },
    input: {
        fontSize: 'x-small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
});

class TemplateScheduleComponentTable extends Component {

    getRoomComponent = name => this.props.componentList.find(component => component.name === name);

    render() {
        const {componentList, classes, doComponent} = this.props;
        return (
            <AbstractTable
                columns={[
                    {title: 'Name', field: 'name'},
                    {title: 'Stereotype', field: 'stereotype'},
                    {
                        title: 'Autorun',
                        field: 'autorun',
                        render: rowData => {
                            return (<Checkbox checked={!!rowData.autorun}/>)
                        }
                    },
                ]}
                data={componentList}
                title="Component List"
                detailPanel={rowData => {
                    return (
                        <TextField
                            label="Component detail"
                            multiline
                            rows={10}
                            rowsMax={15}
                            value={JSON.stringify(this.getRoomComponent(rowData.name), undefined, 2)}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    root: classes.input,
                                },
                            }}
                            margin="normal"
                            helperText="Component"
                            variant="outlined"
                        />
                    )
                }}
                actions={[
                    {
                        icon: EditIcon,
                        tooltip: 'Edit component',
                        onClick: (event, rowData) => doComponent(EDIT, rowData.name),
                    },
                ]}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50],
                    actionsColumnIndex: -1,
                    toolbar: false,
                }}
            />
        );
    }
}

TemplateScheduleComponentTable.propTypes = {
    classes: PropTypes.object.isRequired,
    componentList: PropTypes.array.isRequired,
    doComponent: PropTypes.func.isRequired,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = (authUser) => !!authUser;

export default compose(
    withStyles(styles),
    withRouter,
    withAuthorization(authCondition),
    connect(mapStateToProps)
)(TemplateScheduleComponentTable);
