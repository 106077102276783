import React, {Component} from 'react'
import AbstractTable from '../table/AbstractTable'
import {compose} from 'recompose'
import {withRouter} from 'react-router-dom'
import {TextField, Checkbox} from '@material-ui/core'
import withStyles from '@material-ui/core/es/styles/withStyles'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import PauseIcon from '@material-ui/icons/PauseCircleOutline'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import {DELETE, EDIT, POWER_OFF, POWERON, POWER_ON} from "../../utils/constants";
import PowerStatusIcon from "../common/PowerStatusIcon";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '99%',
    },
    input: {
        fontSize: 'x-small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
});

class RoomComponentTable extends Component {
    getRoomComponent(name) {
        const {componentList} = this.props;
        const componentAsList = componentList.filter(component => component.name === name);
        if (componentAsList.length === 1) {
            return componentAsList[0];
        }
        return ({});
    }

    render() {
        const {componentList, classes, doComponent} = this.props;
        const sortedComponentList = componentList.sort(
            (a, b) => {
                if (a.host_name === b.host_name) {
                    return a.name.localeCompare(b.name);
                }
                return a.host_name.localeCompare(b.host_name);
            });
        return (
            <AbstractTable
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        headerStyle: {
                            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "30%", maxWidth: 200
                        },
                        cellStyle: {
                            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "30%", maxWidth: 200
                        }
                    },
                    {
                        title: 'Hostname',
                        field: 'host_name',
                        headerStyle: {
                            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "30%", maxWidth: 200
                        },
                        cellStyle: {
                            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: "30%", maxWidth: 200
                        }
                    },
                    {
                        title: 'Autorun',
                        field: 'autorun',
                        render: rowData => {
                            return (<Checkbox checked={rowData.autorun}/>)
                        }
                    },
                    {title: 'Status', field: 'status'},
                    {
                        title: 'Power',
                        field: 'cstatus',
                        render: rowData => {
                            return (<PowerStatusIcon powerStatus={rowData.cstatus}/>)
                        }
                    },
                ]}
                data={sortedComponentList}
                title="Component List"
                detailPanel={rowData => {
                    return (
                        <TextField
                            label="Component detail"
                            multiline
                            rows={10}
                            rowsMax={15}
                            value={JSON.stringify(this.getRoomComponent(rowData.name), undefined, 2)}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    root: classes.input,
                                },
                            }}
                            margin="normal"
                            helperText="Component"
                            variant="outlined"
                        />
                    )
                }}
                actions={[
                    {
                        icon: DeleteIcon,
                        tooltip: 'Delete component',
                        onClick: (event, rowData) =>
                            window.confirm("This action will delete the component permanentely and can not be undone, are you sure?")
                            && doComponent(DELETE, rowData.name),
                    },
                    {
                        icon: EditIcon,
                        tooltip: 'Edit component',
                        onClick: (event, rowData) => doComponent(EDIT, rowData.name),
                    },
                    rowData => {
                        const powerOn = rowData.cstatus === POWERON;
                        const icon = powerOn ? PauseIcon : PlayIcon;
                        const action = powerOn ? POWER_OFF : POWER_ON;
                        const tooltip = powerOn ? 'Power off component' : 'Power on component';
                        const confirmation = powerOn ? "stop" : "start"
                        return ({
                            icon,
                            tooltip,
                            onClick: (event, rowData) => {
                                if (window.confirm(`This action will ${confirmation} the component and any dependant ones, are you sure?`)) {
                                    doComponent(action, rowData.name)
                                }
                            },
                        });
                    }
                ]}
                options={{
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                    padding: "dense",
                    pageSize: 20,
                    pageSizeOptions: [20, 40, 60],
                    search: false
                }}
            />
        );
    }
}

export default compose(
    withStyles(styles),
    withRouter,
)(RoomComponentTable);
