import React, {Component} from 'react'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {withStyles} from "@material-ui/core";
import Fab from '@material-ui/core/Fab'
import ReloadIcon from "@material-ui/icons/Cached";

import withAuthorization from '../auth/withAuthorization'

import {buildUrl, doDelete, doGet, doPost, doPut} from '../../utils/http'
import {
    CREATE,
    DELETE,
    UPDATE,
    EDIT,
    EMPTY_BODY,
    POWER_OFF,
    POWER_ON,
    ROOM_UPDATE_INTERVAL,
    GET_ROOM,
    DEACTIVATE_ROOM,
    ACTIVATE_ROOM,
    DELETE_ROOM,
    POWERON_COMPONENT,
    POWEROFF_COMPONENT,
    DELETE_COMPONENT, CREATE_COMPONENT, UPDATE_COMPONENT, ROOM_ACTIVE, ROOM_ERROR, ROOM_INACTIVE, SEVERITY_ERROR
} from '../../utils/constants'
import {showMessage} from '../notification/NotificationSnack';
import {openRoomComponentDialog} from "./ComponentDialog";
import RoomComponentTable from "./RoomComponentTable";
import RoomHeaderComponent from "./RoomHeaderComponent";
import ComponentDialog from "./ComponentDialog";

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexFlow: 'nowrap column',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
});

class RoomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: undefined,
            timerId: undefined,
            lastUpdate: undefined,
        }
    }

    componentDidMount() {
        if (this.props.authUser) {
            const timerId = setInterval(this.updateRoom, ROOM_UPDATE_INTERVAL);
            this.updateRoom();
            this.setState({timerId});
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.timerId);
    }

    updateRoom = () => doGet(buildUrl(GET_ROOM, {id: this.getId()}), this.setRoom, this.onError);

    deleteRoom = () => doDelete(buildUrl(DELETE_ROOM, {id: this.getId()}), this.goBack, this.onError);

    powerOnRoom = () => doPut(buildUrl(ACTIVATE_ROOM, {id: this.getId()}), EMPTY_BODY, this.updateRoom, this.onError);

    powerOffRoom = () => doPut(buildUrl(DEACTIVATE_ROOM, {id: this.getId()}),EMPTY_BODY, this.updateRoom, this.onError);

    powerOnComponent = componentId => doPut(
        buildUrl(POWERON_COMPONENT, {roomId: this.getId(), componentId}),
        EMPTY_BODY,
        this.updateRoom,
        this.onError);

    powerOffComponent = componentId => doPut(
        buildUrl(POWEROFF_COMPONENT, {roomId: this.getId(), componentId}),
        EMPTY_BODY,
        this.updateRoom,
        this.onError);

    deleteComponent = componentId => doDelete(
        buildUrl(DELETE_COMPONENT, {roomId: this.getId(), componentId}),
        this.updateRoom,
        this.onError);

    createComponent = componentAsString => doPost(
        buildUrl(CREATE_COMPONENT, {roomId: this.getId()}),
        componentAsString,
        this.updateRoom,
        this.onError);

    updateComponent = (componentId, componentAsString) => doPut(
        buildUrl(UPDATE_COMPONENT, {roomId: this.getId(), componentId}),
        componentAsString,
        this.updateRoom,
        this.onError);

    editComponent = componentId => openRoomComponentDialog(this.getRoomComponent(componentId));

    getRoomComponent = componentId => {
        const {components} = this.state.room;
        const componentList = components.filter(component => component.name === componentId);
        if (componentList.length === 1) {
            return componentList[0];
        }
        return ({});
    };

    setRoom = json => {
        this.setState({room: json, lastUpdate: new Date()});
    };

    goBack = () => this.props.history.goBack();

    doRoom = (action) => {
        switch (action) {
            case POWER_OFF:
                return this.powerOffRoom();
            case POWER_ON:
                return this.powerOnRoom();
            case DELETE:
                return this.deleteRoom();
            default:
                return 'do nothing';
        }
    };

    doComponent = (action, componentId, component) => {
        switch (action) {
            case POWER_OFF:
                return this.powerOffComponent(componentId);
            case POWER_ON:
                return this.powerOnComponent(componentId);
            case EDIT:
                return this.editComponent(componentId);
            case CREATE:
                return this.createComponent(component);
            case UPDATE:
                return this.updateComponent(componentId, component);
            case DELETE:
                return this.deleteComponent(componentId);
            default:
                return 'do nothing';
        }
    };

    roomStatus = room => {
        if (!room) {
            return ({
                tooltip: 'No status data',
                color: 'grey',
                active: false,
            });
        }
        const status = room.rstatus;
        const active = room.rstatus === ROOM_ACTIVE;
        const tooltip = status === ROOM_ERROR ? 'Error' : status === ROOM_INACTIVE ? 'Switched off' : 'Switched on';
        const color = status === ROOM_ERROR ? 'red' : status === ROOM_INACTIVE ? 'black' : 'green';
        const message = (active === true)  ? 'Power off' : 'Power on';
        const action = (active === true)  ? POWER_OFF : POWER_ON;
        return ({
            tooltip,
            color,
            message,
            action,
        });
    }

    onError = error => showMessage(error, SEVERITY_ERROR);

    getId() {
        return this.props.match.params.id;
    }

    render() {
        const {classes} = this.props;
        const {room, lastUpdate} = this.state;
        const roomStatus = this.roomStatus(room);
        if (!room) return <p>Retrieving data</p>
        return (
            <div className={classes.root}>
                <RoomHeaderComponent room={room} doRoom={this.doRoom}
                                     roomStatus={roomStatus}
                                     requestUpdate={this.updateRoom}
                                     lastUpdate={lastUpdate}
                                     doComponent={this.doComponent}
                                     onError={this.onError}/>
                <RoomComponentTable componentList={room.components} doComponent={this.doComponent}/>
                <Fab className={classes.fab} color={'primary'}>
                    <ReloadIcon onClick={this.updateRoom}/>
                </Fab>
                <ComponentDialog doComponent={this.doComponent} onUploadError={this.onError}/>
            </div>
        );
    }
}


const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = (authUser) => !!authUser;

export default compose(
    withRouter,
    withAuthorization(authCondition),
    withStyles(styles),
    connect(mapStateToProps)
)(RoomComponent)
