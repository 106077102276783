import React, {Component} from 'react'
import {compose} from 'recompose'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import withStyles from '@material-ui/core/styles/withStyles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import TemplateListTable from './TemplateListTable'
import {showMessage} from '../notification/NotificationSnack'
import {
    CREATE_ROOM,
    DELETE_TEMPLATE,
    GET_TEMPLATES,
} from '../../utils/constants'
import {buildUrl, doDelete, doGet, doPost} from '../../utils/http'
import CreateTemplateDialog, {openTemplateDialog} from './CreateTemplateDialog'
import withAuthorization from '../auth/withAuthorization'
import {ROUTE_SCHEDULE_EVENT} from '../../utils/routes'

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
});

const INITIAL_STATE = {
    waiting: true,
    templateList: [],
};

class TemplateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        if (this.props.authUser) {
            this.requestTemplateList();
        }
    }

    handleClickOpen = event => {
        event.preventDefault();
        openTemplateDialog();
    };

    render() {
        const {waiting, templateList} = this.state;
        const {classes} = this.props;
        return (
            <div>
                {(waiting === true) ?
                    <p>Loading data</p>
                    :
                    <TemplateListTable
                        templateList={templateList}
                        deployTemplate={this.deployTemplate}
                        deleteTemplate={this.deleteTemplate}
                        scheduleEvent={this.scheduleEvent}
                    />
                }
                <Fab className={classes.fab} color={'primary'}>
                    <AddIcon onClick={e => this.handleClickOpen(e)}/>
                </Fab>
                <CreateTemplateDialog onUploadComplete={this.onUploadComplete} onUploadError={this.onError}/>
            </div>
        );
    }

    requestTemplateList = () => {
        this.setState({waiting: true});
        doGet(GET_TEMPLATES, this.onTemplateListUpdated, this.onError);
    };

    deleteTemplate = templateId => {
        this.setState({waiting: true});
        doDelete(buildUrl(DELETE_TEMPLATE, {id: templateId}), this.requestTemplateList, this.onError);
    };

    deployTemplate = (templateId, template) => {
        this.setState({waiting: true});
        console.log("template: " + template.definition);
        let json = JSON.parse(template.definition);
        json = {...json, id: templateId};
        doPost(CREATE_ROOM, JSON.stringify(json), this.onCreated, this.onError);
    };

    scheduleEvent = templateId => {
        this.props.history.push(buildUrl(ROUTE_SCHEDULE_EVENT, {id: templateId}));
    };

    onUploadComplete = () => this.requestTemplateList();

    onScheduled = () => {
        this.setState({waiting: false});
        showMessage('Event successfully scheduled.');
    };

    onCreated = () => {
        this.setState({waiting: false});
        showMessage('Configuration successfully deployed in BeRT.');
    };

    onTemplateListUpdated = response => {
        this.setState({
            waiting: false,
            templateList: response
        });
    };

    onError = message => {
        this.setState({waiting: false});
        message && showMessage(message);
    };

}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser
});

const authCondition = authUser => !!authUser;

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    withRouter,
    connect(mapStateToProps),
)(TemplateComponent);
