import React from "react";
import {PropTypes} from 'prop-types'
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    label: {
        fontWeight: 'bold',
    },
    value: {
        marginLeft: 4,
    },
}));

const getValue = value => {
    if (value === true) {
        return 'yes';
    }
    if (value === false) {
        return 'no';
    }
    return value;
};

export default function LabelValue({className, label, value, children}) {
    const classes = useStyles();
    return (
        <div className={className}>
            <Typography variant={"body2"} className={classes.label} display={'inline'}>
                {label}:
            </Typography>
            {
                value !== undefined && value !== null &&
                    <Typography variant={"body2"} className={classes.value} display={'inline'}>
                        {getValue(value)}
                    </Typography>
            }
            {children}
        </div>
    )
}

LabelValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    children: PropTypes.any,
};

LabelValue.defaultProps = {
    className: '',
};
