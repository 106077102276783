import React from 'react'
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Link from "react-router-dom/Link"

function SideMenuItem(props) {
    const {text, icon, link} = props;

    const ItemLink = React.forwardRef((props, ref) => <Link ref={ref} to={link} {...props} />);

    return (
        <ListItem button component={ItemLink}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text}/>
        </ListItem>
    );
}

export default SideMenuItem;
