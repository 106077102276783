import React from 'react'
import {connect} from 'react-redux'
import {compose} from 'recompose'

import withStyles from '@material-ui/core/es/styles/withStyles'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import {doPost} from '../../utils/http'
import {CREATE_TEMPLATE} from '../../utils/constants'
import withAuthorization from '../auth/withAuthorization'

let openDialogFn;

const styles = theme => ({
    jsonTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '99%',
    },

    group: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'space-around',
    },

    textField: {
        margin: theme.spacing(1),
        width: '50%',
    },

    input: {
        fontSize: 'x-small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
});

const INITIAL_STATE = {
    open: false,
    name: '',
    description: '',
    definition: undefined,
    file: undefined,
};

class CreateTemplateDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        openDialogFn = this.openDialog;
    }

    componentWillUnmount() {
        this.setState({...INITIAL_STATE});
    }

    render() {
        const {open, name, description, definition} = this.state;
        const {classes} = this.props;
        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="Create template dialog"
            >
                <DialogTitle id="Create template dialog">Studio Template</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a json file to create a new studio template.
                    </DialogContentText>
                    <div className={classes.group}>
                        <TextField id={'name'}
                                   type={'text'}
                                   label={'Name'}
                                   value={name}
                                   InputProps={{
                                       readOnly: true,
                                   }}
                        />
                        <TextField id={'description'}
                                   type={'text'}
                                   label={'Description'}
                                   value={description}
                                   InputProps={{
                                       readOnly: true,
                                   }}
                        />
                    </div>
                    <TextField
                        label="Template detail"
                        multiline
                        rows={10}
                        rowsMax={15}
                        value={JSON.stringify(definition, undefined, 2)}
                        className={classes.jsonTextField}
                        InputProps={{
                            classes: {
                                root: classes.input,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        helperText="Component"
                        variant="outlined"
                    />
                    <Input
                        autoFocus
                        margin="dense"
                        id="file"
                        type={'file'}
                        label="Select file"
                        accept={'application/json,.json'}
                        fullWidth
                        onChange={event => this.handleFileSelected(event)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleUpload} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    handleFileSelected = event => {
        const reader = new FileReader();
        reader.onload = this.setData;
        reader.readAsText(event.target.files[0]);
    };

    setData = event => {
        const template = JSON.parse(event.target.result);
        template.customer = this.props.authUser.uid;
        this.setState({
            name: template.name,
            description: template.description,
            definition: template,
        });
    };

    openDialog = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({...INITIAL_STATE});
    };

    handleUpload = () => {
        const {definition} = this.state;
        const {onUploadComplete, onUploadError} = this.props;
        doPost(CREATE_TEMPLATE, JSON.stringify(definition), onUploadComplete, onUploadError);
        this.handleClose();
    };

}

export function openTemplateDialog() {
    openDialogFn();
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = authUser => !!authUser;

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    connect(mapStateToProps)
)(CreateTemplateDialog)
