import React, { Component } from 'react'

import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import { auth } from '../../utils/firebase'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from '@material-ui/core/Grid'

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: '',
    error: null,
};

const styles = theme => ({
    form: {
        display: 'flex',
        justifyContent: 'center',
    },
    headline: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    link: {
        textDecoration: 'none'
    },
    p: {
        fontSize: 'small',
        color: 'dodgerBlue'
    },
    error: {
        fontSize: 'small',
        color: 'tomato'
    },
    paper: {
        margin: 10,
        padding: theme.spacing(3),
        textAlign: 'left',
        maxWidth: '600px',
        flex: '0 1 auto'
    }
});

class PassForgetComponent extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = (event) => {
        const { email } = this.state;

        auth.doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    };

    render() {
        const {email, error} = this.state;
        const {classes} = this.props;

        const isInvalid = email === '';

        return (
            <form onSubmit={this.onSubmit} className={classes.form}>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h5" component="h3" className={classes.headline}>
                        Recuperar password
                    </Typography>
                    <Grid container direction={"column"}>
                    <TextField
                        id='email'
                        label="Correo electrónico"
                        className={classes.textField}
                        value={email}
                        onChange={event => this.setState(byPropKey('email', event.target.value))}
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isInvalid}
                        type={'submit'}
                    >
                        Enviar
                    </Button>
                        <p className={classes.p}>
                            Despúes de pulsar el botón "Enviar" recibirás un correo para cambiar el password."
                        </p>
                    { error && <p className={classes.error}>{error.message}</p> }
                    </Grid>
                </Paper>
            </form>
        );
    }
}

export default compose(
    withStyles(styles),
    withRouter
)(PassForgetComponent);
