import React from 'react'

import moment from 'moment'
import {compose} from 'recompose'
import withAuthorization from '../auth/withAuthorization'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {withStyles} from '@material-ui/core/es/styles'
import {DateTimePicker, MuiPickersUtilsProvider} from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import {TextField, Paper, Typography, Button} from '@material-ui/core'

import {buildUrl, doGet, doPost,} from '../../utils/http'
import {EDIT, GET_TEMPLATE, SCHEDULE_EVENT, UPDATE} from '../../utils/constants'
import ComponentDialog, {openRoomComponentDialog} from '../rooms/ComponentDialog'
import TemplateScheduleComponentTable from './TemplateScheduleComponentTable'
import {showMessage} from '../notification/NotificationSnack'
import {TEMPLATE} from "../../utils/routes";

const styles = theme => ({

    root: {
        maxWidth: '960px',
        marginTop: theme.spacing(),
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2),

    },

    jsonTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '99%',
    },

    group: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },

    event: {
        display: 'flex',
        flexFlow: 'wrap row',
        justifyContent: 'space-around',
    },

    template: {
        marginTop: theme.spacing(1),
    },
    textField: {
        margin: theme.spacing(1),
    },
    title: {
        textAlign: 'left',
    },
    subtitle: {
        textAlign: 'left',
        marginBottom: '8px',
    },
    input: {
        fontSize: 'x-small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
});

const INITIAL_STATE = {
    name: '',
    startTime: moment().startOf('hour').add(1, 'hour'),
    stopTime: moment().startOf('hour').add(3, 'hour'),
    template: undefined,
    waiting: true,
};

class TemplateScheduleComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        const templateId = this.getId();
        this.retrieveTemplate(templateId);
    }

    scheduleEvent = e => {
        e.preventDefault();
        this.setState({waiting: true});
        const {name, startTime, stopTime, template} = this.state;
        const template_id = this.getId();
        const event = {
            name,
            template_id,
            template_name: template.name,
            start_time: startTime,
            stop_time: stopTime,
            template: JSON.stringify(template),
        };
        doPost(
            buildUrl(SCHEDULE_EVENT, {id: template_id}),
            JSON.stringify(event),
            this.onScheduled,
            this.onError);
    };

    handleUpdate = event => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleDateChange = id => newDate => {
        const {startTime, stopTime} = this.state;
        if (id === 'startTime' && newDate >= stopTime) {
            this.setState({
                startTime: newDate,
                stopTime: moment(newDate).add(2, 'hour'),
            });
        } else if (id === 'stopTime' && newDate <= startTime) {
            this.setState({
                startTime: moment(newDate).subtract(2, 'hour'),
                stopTime: newDate,
            });
        } else {
            this.setState({[id]: newDate});
        }
    };

    editComponent = componentId => {
        const {index, component} = this.getRoomComponent(componentId);
        openRoomComponentDialog(component, index);
    };

    updateComponent = (component, index) => {
        const {template} = this.state;
        const newComponentList = [...template.components];
        newComponentList[index] = JSON.parse(component);
        this.setState({template: {...template, components: newComponentList}});
    };

    doComponent = (action, componentId, component, index) => {
        switch (action) {
            case EDIT:
                return this.editComponent(componentId);
            case UPDATE:
                return this.updateComponent(component, index);
            default:
                return 'do nothing';
        }
    };

    retrieveTemplate = templateId => {
        this.setState({waiting: true});
        doGet(buildUrl(GET_TEMPLATE, {templateId,}), this.onSuccess, this.onError);
    };

    onScheduled = event => {
        showMessage('Event ' + event.name + ' scheduled successfully.');
        this.props.history.push(TEMPLATE);
    };
    onSuccess = template => {
        const definition =  JSON.parse(template.definition);
        this.setState({waiting: false, template: definition});
    };

    onError = error => {
        this.setState({waiting: false});
        showMessage(error);
    };

    getId() {
        return this.props.match.params.id;
    }

    getRoomComponent(componentId) {
        const {components} = this.state.template;

        const componentIndex = components.findIndex(component => component.name === componentId);
        if (componentIndex >= 0) {
            return {index: componentIndex, component: components[componentIndex]};
        }
        return {index: -1, component: undefined};
    }

    render() {
        const {classes} = this.props;
        const {name, startTime, stopTime, template, waiting} = this.state;
        const disabled = name === '';
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Paper className={classes.root}>
                    <div className={classes.group}>
                        <Typography variant={'h5'} className={classes.title}>
                            New event
                        </Typography>
                        <Typography variant={'caption'} className={classes.subtitle}>
                            Enter new event name and start/stop times.
                        </Typography>
                        <Paper className={classes.event}>
                            <TextField id={'name'} className={classes.textField}
                                       type={'text'}
                                       label={'Event name'}
                                       value={name}
                                       onChange={event => this.handleUpdate(event)}
                            />
                            <DateTimePicker className={classes.textField}
                                label={'Start time'}
                                ampm={false}
                                disablePast={true}
                                value={startTime}
                                format={'ddd, YYYY/MM/DD - HH:mm:ss'}
                                onChange={this.handleDateChange('startTime')}/>
                            <DateTimePicker className={classes.textField}
                                label={'Stop time'}
                                ampm={false}
                                disablePast={true}
                                value={stopTime}
                                format={'ddd, YYYY/MM/DD - HH:mm:ss'}
                                onChange={this.handleDateChange('stopTime')}/>
                        </Paper>
                        {waiting === false && template &&
                            <div className={classes.template}>
                                <Typography variant={'h6'} className={classes.title}>
                                    Template data
                                </Typography>
                                <Typography variant={'caption'} className={classes.subtitle}>
                                    Template name: {template.name}. Template description: {template.description}
                                </Typography>
                                <TemplateScheduleComponentTable
                                    componentList={template.components}
                                    doComponent={this.doComponent}
                                    isLoading={waiting}
                                />
                            </div>
                        }
                        <Button disabled={disabled} onClick={this.scheduleEvent} >Save</Button>
                        < ComponentDialog doComponent={this.doComponent} onUploadError={this.onError}/>
                    </div>
                </Paper>
            </MuiPickersUtilsProvider>
        );

    }
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser
});

const authCondition = (authUser) => !!authUser;

export default compose(
    withRouter,
    withStyles(styles),
    withAuthorization(authCondition),
    connect(mapStateToProps)
)(TemplateScheduleComponent);
