import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'

const styles = {
    card: {
        maxWidth: 280,
        textAlign: 'left',
    },
    media: {
        height: 0,
        width: '280px',
        paddingTop: '56.25%', // 16:9
    },
};

function CardLink(props) {
    const { classes, image, title, description, to } = props;

    const ItemLink = React.forwardRef((props, ref) => <Link ref={ref} to={to} {...props} />);
    return (
        <div>
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={image}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography component="p">
                        {description}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" color="primary" component={ItemLink}>
                        IR
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}

CardLink.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardLink);
