import React, {useState} from 'react';
import PropTypes from "prop-types";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, useMediaQuery,
    useTheme
} from '@material-ui/core';

export default function ConfirmationDialog ({title, content, onConfirmation, children}) {

    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => setOpen(false);

    const handleOk = () => {
        onConfirmation();
        setOpen(false);
    };

    return (
        <>
            {typeof children === "function" ? children(() => setOpen(true)) : children}
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"} fullScreen={fullScreen}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant={"outlined"}>
                        {'CANCEL'}
                    </Button>
                    <Button onClick={handleOk} color="primary" variant={"contained"}>
                        {'OK'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

ConfirmationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onConfirmation: PropTypes.func.isRequired,
};
