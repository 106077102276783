import React from 'react'
import {withStyles} from '@material-ui/core'

import Typography from '@material-ui/core/Typography'

import {BERT_API} from '../../utils/constants'

const styles = theme => ({
    footer: {
        position: 'fixed',
        padding: theme.spacing(1),
        bottom: theme.spacing(1),
        textAlign: 'left',
    },
});

function FooterComponent(props) {
    return (
        <div className={props.classes.footer}>
            <Typography variant="caption" color="textSecondary">
                BeRT Simplest UI -- BeRT API URL: {BERT_API}
            </Typography>
        </div>
    );
}

export default withStyles(styles)(FooterComponent);
