import React from 'react'
import MaterialTable from 'material-table'

import Search from '@material-ui/icons/Search'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import Delete from '@material-ui/icons/Delete'
import DetailPanel from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import Export from '@material-ui/icons/SaveAlt'
import Filter from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import NextPage from '@material-ui/icons/SkipNext'
import PreviousPage from '@material-ui/icons/SkipPrevious'
import SortArrow from '@material-ui/icons/ArrowUpward'
import ThirdStateCheck from '@material-ui/icons/IndeterminateCheckBox'
import ViewColumn from '@material-ui/icons/ViewColumn'

const icons = {
    Add: Add,
    Check: Check,
    Clear: Clear,
    Delete: Delete,
    DetailPanel: DetailPanel,
    Edit: Edit,
    Export: Export,
    Filter: Filter,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: NextPage,
    PreviousPage: PreviousPage,
    ResetSearch: Clear,
    Search: Search,
    SortArrow: SortArrow,
    ThirdStateCheck: ThirdStateCheck,
    ViewColumn: ViewColumn,
};

export const BOOLEAN_FILTER_CHECKED = 'checked';
export const BOOLEAN_FILTER_UNCHECKED = 'unchecked';

export const localization = {
    pagination: {
        labelRowsSelect: 'filas', // rows
        labelRowsPerPage: 'filas por página', // Rows per page:
        labelDisplayedRows: '{from}-{to} de {count}', // {from}-{to} of {count}
        firstAriaLabel: 'Primera página', // First Page
        firstTooltip: 'Primera página', // First Page
        previousAriaLabel: 'Página anterior', // Previous Page
        previousTooltip: 'Página anterior', // Previous Page
        nextAriaLabel: 'Siguiente página', // Next Page
        nextTooltip: 'Siguiente página', // Next Page
        lastAriaLabel: 'Última página', // Last Page
        lastTooltip: 'Última página', // Last Page
    },
    toolbar: {
        nRowsSelected: '{0} Columna(s) seleccionadas', // {0} row(s) selected
        showColumnsTitle: 'Mostrar columnas', // Show Columns
        showColumnsAriaLabel: 'Mostrar columnas', // Show Columns
        exportTitle: 'Exportar', // Export
        exportAriaLabel: 'Exportar', // Export
        exportName: 'Exportar a CSV', // Export as CSV
        exportCSVName: 'Exportar a CSV', // Export as CSV
        exportPDFName: 'Exportar a PDF', // Export as PDF
        searchTooltip: 'Buscar', // Search
        searchPlaceholder: 'Buscar', // Search
    },
    header: {
        actions: 'Acciones', // Actions
    },
    body: {
        emptyDataSourceMessage: 'No hay datos para mostrar', // No records to display
        filterRow: {
            filterTooltip: 'Filtro', // Filter
        },
    },
};

function AbstractTable(props) {
    return (
        <MaterialTable localization={localization} {...props} />
    )
}

AbstractTable.defaultProps = {
    icons: icons,
};

export default AbstractTable;
