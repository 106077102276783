import firebase from 'firebase/app'
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyAOH75iBI_g_P8AC2ekCHKkyrVr8Z8wkAI",
    authDomain: "bert-svr.firebaseapp.com",
    databaseURL: "https://bert-svr.firebaseio.com",
    projectId: "bert-svr",
    storageBucket: "bert-svr.appspot.com",
    messagingSenderId: "435906358335"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();

export {
    auth,
};
