import React from 'react';
import {compose} from 'recompose'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {doPost} from '../../utils/http'
import {CREATE_ROOM} from "../../utils/constants";
import withAuthorization from "../auth/withAuthorization";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/es/styles/withStyles";

let openDialogFn;

const styles = theme => ({
    jsonTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '99%',
    },
    input: {
        fontSize: 'small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
});

const INITIAL_STATE = {
    open: false,
    room: undefined,
    errorMessage: '',
    error: false,
};

class CreateRoomDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE}
    }

    handleFileSelected = event => {
        this.setState({
            error: false,
            errorMessage: '',
        });
        const reader = new FileReader();
        reader.onload = this.setRoom;
        reader.readAsText(event.target.files[0]);
    };

    setRoom = event => {
        try {
            const room = JSON.parse(event.target.result);
            this.setState({room});
        } catch (error) {
            this.setState({
                error: true,
                errorMessage: error.message,
                room: event.target.result,
            });
        }
    };

    componentDidMount() {
        openDialogFn = this.openDialog;
    }

    componentWillUnmount() {
        this.setState({
            file: undefined,
        });
    }

    openDialog = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleUpload = () => {
        const {room} = this.state;
        const {onUploadComplete, onUploadError} = this.props;
        doPost(CREATE_ROOM, JSON.stringify(room), onUploadComplete, onUploadError);
        this.handleClose();
    };

    render() {
        const {open, room, errorMessage, error} = this.state;
        const {classes} = this.props;
        const canUpload = !!room && !error;
        const value = typeof room === 'string' ? room : JSON.stringify(room, undefined, 2);
        return (
            <Dialog open={open} onClose={this.handleClose} fullWidth={true} maxWidth={'lg'}>
                <DialogTitle>Upload new studio room {!!room && ` -- ${room.name} (${room.customer})`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select json file describing an studio room to upload.
                    </DialogContentText>
                    <TextField
                        label="Room definition"
                        multiline
                        rows={15}
                        rowsMax={25}
                        value={value}
                        className={classes.jsonTextField}
                        InputProps={{
                            classes: {
                                root: classes.input,
                            },
                            readOnly: true,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        helperText={errorMessage}
                        error={error}
                        variant="outlined"
                    />
                    <input
                        autoFocus
                        type={'file'}
                        accept={'application/json,.json'}
                        onChange={event => this.handleFileSelected(event)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleUpload} color="primary" disabled={!canUpload}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export function openCreateRoomDialog() {
    openDialogFn();
}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = authUser => !!authUser;

export default compose(
    withAuthorization(authCondition),
    withStyles(styles),
    connect(mapStateToProps)
)(CreateRoomDialog);
