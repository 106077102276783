export const BERT_PROTOCOL = process.env.REACT_APP_BERT_PROTOCOL;
export const BERT_SERVER = process.env.REACT_APP_BERT_SERVER;
export const BERT_PORT = process.env.REACT_APP_BERT_PORT;
export const BERT_API = BERT_PROTOCOL + '://' + BERT_SERVER + ':' + BERT_PORT + '/bert/3.00/';

export const BERT_S_PROTOCOL = process.env.REACT_APP_BERT_S_PROTOCOL;
export const BERT_S_SERVER = process.env.REACT_APP_BERT_S_SERVER;
export const BERT_S_PORT = process.env.REACT_APP_BERT_S_PORT;
export const BERT_S_API = BERT_S_PROTOCOL + '://' + BERT_S_SERVER + ':' + BERT_S_PORT + '/api/';

export const EMPTY_BODY = '';
/**
 * Value en milliseconds
 * @type {number}
 */
export const ROOM_UPDATE_INTERVAL = 60 * 1000; // One minute.

export const ROOM_ACTIVE='active';
export const ROOM_INACTIVE='inactive';
export const ROOM_ERROR='error';

export const CREATE='create';
export const UPDATE='update';
export const EDIT='edit';
export const DELETE='delete';
export const POWER_ON='power_on';
export const POWER_OFF='power_off';
export const POWERON='poweron';
export const POWEROFF='poweroff';

// URLS
export const GET_ROOM = BERT_API + 'room/:id';
export const CREATE_ROOM = BERT_API + 'room';
export const DELETE_ROOM = BERT_API + 'room/:id';
export const ACTIVATE_ROOM = BERT_API + 'room/:id/activate';
export const DEACTIVATE_ROOM = BERT_API + 'room/:id/deactivate';

export const POWEROFF_COMPONENT = BERT_API + 'room/:roomId/component/:componentId/poweroff';
export const POWERON_COMPONENT = BERT_API + 'room/:roomId/component/:componentId/poweron';
export const DELETE_COMPONENT = BERT_API + 'room/:roomId/component/:componentId';
export const CREATE_COMPONENT = BERT_API + 'room/:roomId/component';
export const UPDATE_COMPONENT = BERT_API + 'room/:roomId/component/:componentId';

export const GET_TEMPLATE = BERT_S_API + 'template/:templateId';
export const GET_TEMPLATES = BERT_S_API + 'template';
export const CREATE_TEMPLATE = BERT_S_API + 'template';
export const DELETE_TEMPLATE = BERT_S_API + 'template/:id';

export const SCHEDULE_EVENT = BERT_S_API + 'template/:id/event';
export const GET_EVENTS_BY_TEMPLATE = BERT_S_API + 'template/:id/event';

/**
 * NOTIFICATION SEVERITY
 */
export const SEVERITY_SUCCESS = 'success';
export const SEVERITY_INFO = 'info';
export const SEVERITY_WARNING = 'warning';
export const SEVERITY_ERROR = 'error';
