import React from 'react';

import {Typography, withStyles} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import {CREATE, UPDATE} from "../../utils/constants";

let openDialogFn;

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '99%',
    },
    input: {
        fontSize: 'x-small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
    error: {

    }
});

class ComponentDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            error: undefined
        };
    }

    handleUpdate = (event) => {
        const json = this.checkSyntax(event.target.value);
        this.setState({component: json});
    };

    componentDidMount() {
        openDialogFn = this.openDialog;
    }

    componentWillUnmount() {
        this.setState({
            component: undefined,
        });
    }

    openDialog = (component, index) => {
        const componentId = component && component.name;
        const action = component ? UPDATE : CREATE;
        this.setState({
            open: true,
            componentId,
            index: index,
            action,
            component: JSON.stringify(component, undefined, 2),
        });
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleSave = () => {
        const {component, componentId, action, index} = this.state;
        this.props.doComponent(action, componentId, component, index);
        this.handleClose();
    };

    checkSyntax = json => {
        try {
            JSON.parse(json);
            this.setState({error: undefined});
        }  catch (error) {
            this.setState({error});
        }
        return json;
    };

    render() {
        const {component, open, error} = this.state;
        const {classes} = this.props;
        return (
            <Dialog open={open} onClose={this.handleClose} fullWidth={true} maxWidth={'xl'}>
                <DialogTitle>Component editor</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit or create a new component.
                    </DialogContentText>
                    <TextField
                        label="Component detail"
                        multiline
                        rows={10}
                        rowsMax={15}
                        value={component}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                root: classes.input,
                            },
                        }}
                        margin="normal"
                        variant="outlined"
                        onChange={event => this.handleUpdate(event)}
                    />
                    <Typography variant={'caption'} color={'secondary'}>
                        {error && JSON.stringify(error.message)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button color="primary" disabled={error !== undefined} onClick={this.handleSave} >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export function openRoomComponentDialog(component, index) {
    openDialogFn(component, index);
}

ComponentDialog.propTypes = {
    doComponent: PropTypes.func.isRequired,
};

export default withStyles(styles)(ComponentDialog);
