import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose} from 'recompose'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import HomeIcon from '@material-ui/icons/Home'
import RoomIcon from '@material-ui/icons/Tune'

import * as routes from '../../utils/routes'
import SideMenuItem from './SideMenuItem'
import {auth} from '../../utils/firebase'

const styles = {
    root: {
        flexGrow: 1,
        position: 'fixed',
        top: '0px',
        width: '100%',
        zIndex: 10,
    },
    flex: {
        flex: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
};

class SideMenu extends React.Component {

    state = {
        top: false,
        left: false,
        bottom: false,
        right: false,
        anchorEl: null,
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleSignOut = () => {
        this.handleClose();
        auth.doSignOut();
        this.props.history.push(routes.LANDING);
    };

    handleSignIn = () => {
        this.handleClose();
        this.props.history.push(routes.SIGN_IN);
    };

    handleChangePassword = () => {
        this.handleClose();
        this.props.history.push(routes.PASS_CHANGE);
    };

    render() {
        const {classes, authUser} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        const sideList = (
            <div className={classes.list}>
                <div>
                    <SideMenuItem text={"Inicio"} icon={<HomeIcon/>} link={routes.LANDING} {...this.props}/>
                    <Divider/>
                    {authUser &&
                    (
                        <div>
                            <SideMenuItem text={"Studio rooms"} icon={<RoomIcon/>}
                                          link={routes.ROOM_LIST} {...this.props}/>
                        </div>
                    )
                    }
                </div>
            </div>
        );

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Menu"
                            onClick={this.toggleDrawer('left', true)}>
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            BeRT Simple UI
                        </Typography>
                        <div>
                            <IconButton
                                aria-owns={open ? 'menu-appbar' : null}
                                aria-haspopup="true"
                                onClick={this.handleMenu}
                                color="inherit"
                            >
                                <AccountCircle/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                {authUser
                                    ? (
                                        <div>
                                            <MenuItem onClick={this.handleSignOut}>Salir</MenuItem>
                                            <MenuItem onClick={this.handleChangePassword}>Cambiar password</MenuItem>
                                        </div>)
                                    : <MenuItem onClick={this.handleSignIn}>Entrar</MenuItem>
                                }
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <div>
                    <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={this.toggleDrawer('left', false)}
                            onKeyDown={this.toggleDrawer('left', false)}
                        >
                            {sideList}
                        </div>
                    </Drawer>
                </div>
            </div>
        );
    }
}

SideMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser
});

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps)
)(SideMenu);
