import React, { Component } from 'react';
import {compose} from 'recompose'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import {createMuiTheme, withStyles} from '@material-ui/core/styles'
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom'

import LandingComponent from './components/LandingComponent'
import SignInComponent from './components/auth/SignInComponent'
import PassChangeComponent from './components/auth/PassChangeComponent'
import PassForgetComponent from './components/auth/PassForgetComponent'
import SideMenu from './components/navigation/SideMenu'
import * as routes from './utils/routes'
import withAuthentication from './components/auth/withAuthentication'

import RoomListComponent from './components/rooms/RoomListComponent'
import RoomComponent from './components/rooms/RoomComponent'
import FooterComponent from './components/navigation/FooterComponent'
import TemplateComponent from './components/template/TemplateComponent'
import NotificationSnack from './components/notification/NotificationSnack'
import TemplateScheduleComponent from './components/template/TemplateScheduleComponent'

const styles = theme => ({
    root: {
        textAlign: 'center',
    },
    content: {
        marginTop: theme.spacing(1)*9,
        marginBottom: theme.spacing(1)*6,
    }
});

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
});

class App extends Component {
    render() {
        const {classes} = this.props;
        return (
            <MuiThemeProvider theme={theme}>
                <Router basename={'/' + process.env.REACT_APP_BASE_ROUTE + '/'}>
                    <div className="App">
                        <SideMenu/>
                        <div className={classes.content}>
                            <Route exact path={routes.LANDING} component={() => <LandingComponent/>}/>
                            <Route exact path={routes.SIGN_IN} component={() => <SignInComponent/>}/>
                            <Route exact path={routes.PASS_FORGET} component={() => <PassForgetComponent/>}/>
                            <Route exact path={routes.PASS_CHANGE} component={() => <PassChangeComponent/>}/>
                            <Route exact path={routes.ROOM_LIST} component={() => <RoomListComponent/>}/>
                            <Route exact path={routes.ROOM} component={() => <RoomComponent/>}/>
                            <Route exact path={routes.ROUTE_SCHEDULE_EVENT} component={() => <TemplateScheduleComponent/>}/>
                            <Route exact path={routes.TEMPLATE} component={() => <TemplateComponent/>}/>
                        </div>
                        <NotificationSnack/>
                        <FooterComponent/>
                    </div>
                </Router>
            </MuiThemeProvider>
        );
    }
}

export default compose(
    withAuthentication,
    withStyles(styles),
)(App);
