import React from 'react'

import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import {compose} from 'recompose'
import PropTypes from 'prop-types'

import DeleteIcon from '@material-ui/icons/Delete'
import DeployIcon from '@material-ui/icons/FlightTakeoff'
import ScheduleIcon from '@material-ui/icons/Schedule'
import {TextField} from "@material-ui/core";

import AbstractTable from '../table/AbstractTable'
import withAuthorization from '../auth/withAuthorization'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '99%',
    },
    input: {
        fontSize: 'x-small',
        lineHeight: '1.5em',
        fontFamily: 'monospace'
    },
});

function TemplateListTable(props) {
    const {classes, templateList, deleteTemplate, deployTemplate, scheduleEvent} = props;
    return (
        <AbstractTable
            columns={[
                { title: 'Id', field: 'id' },
                { title: 'Name', field: 'name' },
                { title: 'Description', field: 'description' },
            ]}
            data={templateList}
            title="Available studio room configurations"
            detailPanel={rowData => {
                const json = JSON.parse(rowData.definition);
                return (
                    <TextField
                        label="Studio room configuration detail"
                        multiline
                        rows={10}
                        rowsMax={15}
                        value={JSON.stringify(json, undefined, 2)}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                root: classes.input,
                            },
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                )
            }}
            actions={[
                {
                    icon: DeleteIcon,
                    tooltip: 'Delete template',
                    onClick: (event, rowData) => deleteTemplate(rowData.id),
                },
                {
                    icon: DeployIcon,
                    tooltip: 'Deploy in BeRT',
                    onClick: (event, rowData) => deployTemplate(rowData.id, rowData),
                },
                {
                    icon: ScheduleIcon,
                    tooltip: 'Schedule event',
                    onClick: (event, rowData) => scheduleEvent(rowData.id),
                },
            ]}
            options={{
                actionsColumnIndex: -1,
                showEmptyDataSourceMessage: true,
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: 'No records to display',
                },
            }}
        />
    );
}

TemplateListTable.propTypes = {
    classes: PropTypes.object.isRequired,
    templateList: PropTypes.array.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
    deployTemplate: PropTypes.func.isRequired,
    scheduleEvent: PropTypes.func.isRequired,
};

const mapStateToProps = ({session}) => ({
    authUser: session.authUser
});

const authCondition = (authUser) => !!authUser;

export default compose(
    withRouter,
    withAuthorization(authCondition),
    withStyles(styles),
    connect(mapStateToProps)
)(TemplateListTable);
