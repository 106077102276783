import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';

import { firebase } from '../../utils/firebase';
import * as routes from '../../utils/routes';

const withAuthorization = (authCondition) => (Component) => {

    class WithAuthorization extends React.Component {

        componentDidMount() {
            firebase.auth.onAuthStateChanged(authUser => {
                if (!authCondition(authUser)) {
                    this.props.history.push(routes.SIGN_IN);
                }
            });
        }

        render() {
            const { authUser } = this.props;
            return authUser ? <Component {...this.props}/> : null;
        }
    }

    const mapStateToProps = ({ session }) => ({
       authUser: session.authUser
    });

    return compose(
        withRouter,
        connect(mapStateToProps),
    )(WithAuthorization);
};

export default withAuthorization;