import React, {Component} from 'react';
import {compose} from 'recompose'
import {connect} from 'react-redux'

import withStyles from '@material-ui/core/styles/withStyles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import {BERT_API} from '../../utils/constants'
import {doGet} from '../../utils/http'

import RoomListTable from './RoomListTable'
import CreateRoomDialog, {openCreateRoomDialog} from './CreateRoomDialog'

import {showMessage} from '../notification/NotificationSnack'
import withAuthorization from "../auth/withAuthorization";

const styles = theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        boxSizing: 'border-box'
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
});

const INITIAL_STATE = {
    waiting: true,
    roomList: [],
};

class RoomListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        if (this.props.authUser) {
            this.updateRoomList();
        }
    }

    handleClickOpen = event => {
        event.preventDefault();
        openCreateRoomDialog();
    };

    render() {
        const {waiting, roomList} = this.state;
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                {
                    (waiting === true) ?
                        <p>Waiting</p> : < RoomListTable roomList = {roomList}/>
                }
                <Fab className={classes.fab} color={'primary'}>
                    <AddIcon onClick={e => this.handleClickOpen(e)}/>
                </Fab>
                <CreateRoomDialog onUploadComplete={this.onUploadComplete} onUploadError={this.onError}/>
            </div>
        );
    };

    onUploadComplete = () => this.updateRoomList();

    updateRoomList() {
        this.setState({waiting: true});
        doGet(BERT_API + 'room', this.onRoomListUpdated, this.onError);
    }

    onRoomListUpdated = response => {
        this.setState({
            waiting: false,
            roomList: response
        });
    };

    onError = message => {
        this.setState({waiting: false});
        message && showMessage(message);
    };

}

const mapStateToProps = ({session}) => ({
    authUser: session.authUser,
});

const authCondition = (authUser) => !!authUser;


export default compose(
    withStyles(styles),
    withAuthorization(authCondition),
    connect(mapStateToProps),
)(RoomListComponent);
