import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Divider, useTheme, useMediaQuery
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import LabelValue from "../common/LabelValue";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {DELETE} from "../../utils/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import PowerOffIcon from "@material-ui/icons/PowerOff";
import ReloadIcon from "@material-ui/icons/Cached";
import ConfirmationDialog from "../common/ConfirmationDialog";

const useStyles = makeStyles(theme => ({
    card: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(1),
        textAlign: 'left',
    },
    detail: {
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(2)
    },
    content: {
        display: "flex",
        flexFlow: "wrap row",
        justifyContent: "space-between"
    },
    divider: {
        height: 2,
        marginBottom: theme.spacing(2)
    },
    actions: {
        marginRight: theme.spacing(2)
    },
    close: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const RoomDialogContent = ({room, roomStatus, doRoom, requestUpdate, lastUpdate}) => {

    const classes = useStyles();
    const messages = {
        delete: 'Delete room',
        power_on: 'Power On',
        power_off: 'Power Off',
    };

    if (!room) {
        return (<div/>)
    }
    return (
        <div className={classes.content}>
            <div className={classes.detail}>
                <LabelValue label={'Id'} value={room.id}/>
                <LabelValue label={'Name'} value={room.name}/>
                <LabelValue label={'Event id'} value={room.event_id}/>
                <LabelValue label={'Event type'} value={room.event_type}/>
                <LabelValue label={'Customer'} value={room.customer}/>
                <LabelValue label={"Status"} value={roomStatus.tooltip}/>
                <LabelValue label={"Auto power on"} value={room.autorun}/>
            </div>
            <div className={classes.actions}>
                <Typography variant="subtitle2" color="textSecondary">
                    Actions
                </Typography>
                <ConfirmationDialog title={"Delete confirmation"}
                                    content={'You are about to delete a room. This operation can not be undone. Please mind that you may need to delete de event at tiivii.com too. Are you sure?'}
                                    onConfirmation={() => doRoom(DELETE)}>
                    {
                        handleClick => (
                            <Tooltip title={messages.delete}>
                                <IconButton variant={"contained"} color={'secondary'} size={'small'}
                                            aria-label={messages.delete}
                                            onClick={handleClick}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </ConfirmationDialog>
                <ConfirmationDialog title={"Stop confirmation"}
                                    content={'You are about to stop a room. This action will stop every component, including publishers and recorders. Are you sure?'}
                                    onConfirmation={() => doRoom(roomStatus.action)}>
                    {
                        handleClick => (
                            <Tooltip title={messages.power_off}>
                                <IconButton variant={'outlined'} color={'primary'} size={'small'}
                                            aria-label={roomStatus.message} onClick={handleClick}>
                                    <PowerOffIcon/>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </ConfirmationDialog>
                <Tooltip title={'Last update: ' + (lastUpdate && lastUpdate.toLocaleString())}>
                    <IconButton variant={"outlined"} color={'primary'} size={'small'}
                                aria-label={'Update'}
                                onClick={requestUpdate}>
                        <ReloadIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}

const RoomDetailDialog = ({room, roomStatus, doRoom, requestUpdate, lastUpdate}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <InfoIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"} fullScreen={fullScreen}>
                <DialogTitle>{!!room ? room.name : 'Room detail'}</DialogTitle>
                <DialogContent>
                    <RoomDialogContent room={room} roomStatus={roomStatus} doRoom={doRoom} requestUpdate={requestUpdate} lastUpdate={lastUpdate}/>
                </DialogContent>
                <Divider variant={"middle"} className={classes.divider}/>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant={"outlined"} autoFocus
                            className={classes.close}>
                        close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

RoomDetailDialog.propTypes = {
    room: PropTypes.object,
    roomStatus: PropTypes.object,
    doRoom: PropTypes.func.isRequired,
    requestUpdate: PropTypes.func.isRequired,
    lastUpdate: PropTypes.any
};

export default RoomDetailDialog;
