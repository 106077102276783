import React from 'react'
import {useHistory} from 'react-router-dom'

import AbstractTable from '../table/AbstractTable'
import Launch from '@material-ui/icons/Launch'
import {ROOM_BASE_ROUTE} from '../../utils/routes'

export default function RoomListTable(props) {
    const {roomList} = props;
    const history = useHistory();

    return (
        <AbstractTable
            columns={[
                { title: 'Id', field: 'id' },
                { title: 'Room', field: 'name' },
                { title: 'Description', field: 'description' },
            ]}
            data={roomList}
            title="Room List"
            actions={[
                {
                    icon: Launch,
                    tooltip: 'Show Room Details',
                    onClick: (event, rowData) => history.push(`${ROOM_BASE_ROUTE}/${rowData.id}`)
                },
            ]}
            options={{
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false,
                padding: "dense",
                search: false,
                showEmptyDataSourceMessage: true
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: 'No records to display',
                },
            }}
        />
    );
}
