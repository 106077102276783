import React from 'react';
import PropTypes from 'prop-types';

import {Button, Card, CardContent, Typography, makeStyles} from "@material-ui/core";

import RoomDetailDialog from "./RoomDetailDialog";
import {openRoomComponentDialog} from "./ComponentDialog";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
        height: "100%",
        display: "relative",

    },
    text: {
        textOverflow: 'ellipsis',
        whiteSpace: 'wrap',
        overflow: 'hidden',
    },
    title: {
        display: "flex",
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        height: "100%",
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            maxWidth: '84%',
            height: "auto",
        },
    },
    content: {
        display: "flex",
        flexFlow: 'nowrap row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        height: "100%",
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'wrap row',
        },
    },
    actions: {
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "flex-end",
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: "auto",
        },
    },
    action: {
        margin: theme.spacing(0, 1),
    },
    leftIcon: {
        marginRight: theme.spacing(1)
    }
}));

const RoomHeaderComponent = ({room, roomStatus, doRoom, requestUpdate, lastUpdate}) => {
    const classes = useStyles();

    const handleClickOpen = event => {
        event.preventDefault();
        openRoomComponentDialog();
    };

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <div className={classes.title}>
                    <Typography variant="h6" className={classes.text}>
                        {'Studio room: "' + room.name + '"'}
                    </Typography>
                    <RoomDetailDialog room={room} roomStatus={roomStatus} doRoom={doRoom} requestUpdate={requestUpdate} lastUpdate={lastUpdate}/>
                </div>
                <div className={classes.actions}>
                    <Button variant={"outlined"} color={'primary'} size={'small'} className={classes.action}
                            aria-label={'new-component'}
                            onClick={handleClickOpen}>
                        new component
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

RoomHeaderComponent.propTypes = {
    room: PropTypes.object,
    roomStatus: PropTypes.object,
    doRoom: PropTypes.func,
    requestUpdate: PropTypes.func,
    lastUpdate: PropTypes.object
};

export default RoomHeaderComponent;
