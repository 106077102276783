import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {compose} from 'recompose'
import {auth} from '../../utils/firebase'
import * as routes from '../../utils/routes'
import Paper from "@material-ui/core/Paper"
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from '@material-ui/core/Grid'
import PasswordForgetLink from "./PassForgetLink";

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value,
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

const styles = theme => ({
    form: {
        display: 'flex',
        justifyContent: 'center',
    },
    headline: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    error: {
        fontSize: 'small',
        color: 'tomato'
    },
    paper: {
        margin: 10,
        padding: theme.spacing(3),
        textAlign: 'left',
        maxWidth: '600px',
        flex: '0 1 auto'
    }
});

class SignInComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = (event) => {
        const {email, password} = this.state;
        const {history} = this.props;

        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState(() => ({...INITIAL_STATE}));
                history.push(routes.LANDING);
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    };

    render() {
        const {email, password, error} = this.state;
        const {classes} = this.props;

        const isInvalid = password === '' || email === '';

        return (
            <form onSubmit={this.onSubmit} className={classes.form}>
                <Paper className={classes.paper} elevation={1}>
                    <Typography variant="h5" component="h3" className={classes.headline}>
                        Entrada
                    </Typography>
                    <Grid container direction={"column"}>
                        <TextField
                            id='email'
                            label="Correo electrónico"
                            className={classes.textField}
                            value={email}
                            onChange={event => this.setState(byPropKey('email', event.target.value))}
                            margin="normal"
                        />
                        <TextField
                            id='password'
                            label="Contraseña"
                            className={classes.textField}
                            type="password"
                            value={password}
                            onChange={event => this.setState(byPropKey('password', event.target.value))}
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isInvalid}
                            type={'submit'}
                        >
                            Enviar
                        </Button>
                        <PasswordForgetLink/>
                        {error && <p className={classes.error}>{error.message}</p>}
                    </Grid>
                </Paper>
            </form>
        );
    }
}

export default compose(
    withStyles(styles),
    withRouter
)(SignInComponent);
